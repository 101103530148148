/* eslint-disable max-len */
const de = {
  translation: {
    homepage: {
      readNewsitem: 'Neuigkeit lesen',
      seeAll: 'Alle anzeigen',
      back: 'Zurück',
      latestNews: 'Neueste Nachrichten',
      quickLinks: 'Schnelllinks',
    },
    navigation: {
      more: 'Mehr',
      previous: 'Zurück',
      next: 'Weiter',
      toggle: 'Navigationsmenü umschalten',
      backToOverview: 'Zurück zur Übersicht',
      home: 'Home',
      news: 'Neuigkeiten',
      workplace: 'Arbeitsplatz',
      hr: 'HR',
    },
    404: {
      oops: 'Ups!',
      notFoundTitle: 'Seite nicht gefunden',
      notFoundText:
        'Die gesuchte Seite konnte nicht gefunden werden.“ Bitte gehen Sie zurück zur <homeLink>Homepage</homeLink> und versuchen Sie es erneut.',
    },
    storyblok: {
      errorTitle: 'Ein Fehler ist aufgetreten',
      errorMessage: 'Bitte versuchen Sie es später noch einmal',
      noPostsFound: 'Keine Beiträge gefunden',
    },
    news: {
      title: 'Neuigkeiten',
      subTitle: 'Suchen und finden Sie die neuesten Nachrichten',
      likes_one: '{{count}} Like',
      likes_few: '{{count}} Likes',
      likes_other: '{{count}} Likes',
      comments: 'Kommentare',
      relatedPostsTitle: 'Ähnliche Beiträge',
      commentsTitle: 'Kommentare',
      search: 'Zu suchenden Text eingeben...',
    },
    settings: {
      title: 'Einstellungen',
      subTitle: 'Von hier aus können Sie die App-Einstellungen anpassen.',
      language: 'Bevorzugte Sprache',
      notifications: 'Benachrichtigungen',
      en: 'English language',
      de: 'Deutsche Sprache',
      enableNotifications: 'Benachrichtigungen aktivieren',
    },
    filters: {
      error: 'Filters are not available for now. Please try again later.',
      all: 'Alle',
    },
  },
};

export default de;
