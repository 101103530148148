import PropTypes from 'prop-types';
import { Drawer } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { getLink } from '../../utils/link-utils';
import { NavLink } from '../../i18n/Link';
import { DEFAULT_LANGUAGE } from '../../constants/language';
import { ArrowIcon } from '../icons/Icons';

export const SubNavigation = ({ openNav, stories, isLoading, error }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {!isLoading && !error && (
        <Drawer
          className="shadow-none bg-light pb-[160px] px-5 z-10 transform-gpu flex items-end lg:items-start lg:justify-center lg:text-center lg:pt-32"
          overlayProps={{
            className: 'bg-transparent backdrop-blur-none shadow-none',
          }}
          overlay={false}
          open={openNav}
          size={window.innerWidth}
        >
          <div className="flex flex-col">
            {stories?.map((item) => {
              const buttonProps =
                item.link.linktype === 'story'
                  ? {
                      to: `${
                        i18n.language === DEFAULT_LANGUAGE ? '/' : ''
                      }${getLink(item.link)}`,
                      target: item.link.target,
                    }
                  : {
                      href: item.link.cached_url,
                      target: '_blank',
                      rel: 'noreferrer',
                    };

              const Component = item.link.linktype === 'story' ? NavLink : 'a';

              return (
                <Component
                  key={item.link.cached_url}
                  className="text-blue-600 text-[26px] [&:not(:first-child)]:mt-4 font-bold hover:text-brand-secondary flex items-center group"
                  {...buttonProps}
                >
                  {item.title}
                  <ArrowIcon className="ml-2 !fill-blue-600 group-hover:fill-brand-secondary" />
                </Component>
              );
            })}

            <NavLink
              to="/settings"
              className="text-blue-600 text-[26px] [&:not(:first-child)]:mt-4 font-bold hover:text-brand-secondary flex items-center group"
            >
              {t('settings.title')}
              <ArrowIcon className="ml-2 !fill-blue-600 group-hover:fill-brand-secondary" />
            </NavLink>
          </div>
        </Drawer>
      )}
      {error && (
        <Drawer
          className="shadow-none bg-lightGray pb-[160px] px-5 z-10 transform-gpu flex items-end"
          overlayProps={{
            className: 'bg-transparent backdrop-blur-none shadow-none',
          }}
          overlay={false}
          open={openNav}
          size={window.innerWidth}
        >
          <></>
        </Drawer>
      )}
    </>
  );
};

SubNavigation.propTypes = {
  openNav: PropTypes.bool.isRequired,
};
