import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowIcon } from './icons/Icons';
import { getLink } from '../utils/link-utils';
import { DEFAULT_LANGUAGE } from '../constants/language';
import { useTranslation } from 'react-i18next';

export const Button = ({ blok }) => {
  const { i18n } = useTranslation();

  const commonClasses =
    'my-2 py-3 px-5 rounded-xl text-brand-secondary inline-flex self-start font-medium items-center group bg-brand-primary hover:text-brand-primary hover:bg-brand-secondary';

  const linkType = blok.link.linktype;
  const isStory = linkType === 'story';

  const buttonProps = isStory
    ? {
        to: `${i18n.language === DEFAULT_LANGUAGE ? '/' : ''}${getLink(
          blok.link
        )}`,
        target: blok.link.target,
      }
    : {
        href: blok.link.cached_url,
        target: '_blank',
        rel: 'noreferrer',
      };

  const Component = isStory ? Link : 'a';

  return (
    <Component
      data-testid={isStory ? 'button' : 'button-link'}
      className={commonClasses}
      {...buttonProps}
    >
      <span className="text-sm lg:text-base">{blok.title}</span>
      <ArrowIcon className="ml-2 fill-white group-hover:fill-brand-primary" />
    </Component>
  );
};

Button.propTypes = {
  blok: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.shape({
      linktype: PropTypes.string.isRequired,
      target: PropTypes.string,
      cached_url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
