import PropTypes from 'prop-types';
import { Navigation } from './navigation/Navigation';

export const Layout = ({ children }) => {
  return (
    <div
      className="flex flex-col pb-24 lg:pb-0 bg-white lg:h-screen lg:justify-between"
      data-testid="content-page-container"
    >
      <div className="justify-center w-full max-w-screen-xl mx-auto lg:order-2">
        {children}
      </div>
      <Navigation />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
