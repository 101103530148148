import PropTypes from 'prop-types';

export const PageHeader = ({ title, subtitle, children }) => {
  return (
    <header
      data-testid="page-header"
      className="flex flex-col bg-brand-primary rounded-b-2xl justify-end px-5 lg:px-14 pt-24 pb-8"
    >
      <h1 className="text-3xl leading-7 text-brand-secondary font-medium font-founders-grotesque">
        {title}
      </h1>
      {subtitle && <p className="text-sm text-darkGray mt-4 h-9">{subtitle}</p>}
      {children}
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.object,
};
