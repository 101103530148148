import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { transformImage } from '../utils/image-utils';
import { SkeletonLoader } from './SkeletonLoader';

export const Image = (props) => {
  const [srcSet, setSrcSet] = useState('');
  const [src, setSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const imgSrc = props?.src;
  const imgSrcSet = props?.srcSet;
  const imgBlok = props?.blok;
  const imgFile = props?.image?.filename;

  const computeImages = useCallback((imgSrc, imgSrcSet, imgBlok, file) => {
    const computedSrc =
      imgSrc ?? transformImage(file, { width: 480, height: 0 }) + ' 480w';
    setSrc(computedSrc);

    const transformedImages = [
      transformImage(file, {
        width: 640,
        height: 0,
      }) + ' 640w',
      transformImage(file, { width: 768, height: 0 }) + ' 768w',
    ];

    const computedSrcSet = imgSrcSet ?? transformedImages.join(', ').toString();
    setSrcSet(computedSrcSet);
  }, []);

  useEffect(() => {
    computeImages(imgSrc, imgSrcSet, imgBlok, imgFile);
  });

  return (
    <>
      <figure
        className={props?.className}
        style={{ display: isLoading ? 'none' : 'block' }}
      >
        <img
          className={`object-cover object-center rounded-[inherit] ${
            props?.imgClasses ?? ''
          }`}
          data-testid="news-image"
          src={src}
          srcSet={srcSet}
          alt={props?.alt || props?.image?.alt}
          onLoad={() => setIsLoading(false)}
        />
        {props.label && (
          <figcaption data-testid="image-label" className="text-xs mt-2">
            {props?.label}
          </figcaption>
        )}
      </figure>
      {isLoading && (
        <SkeletonLoader
          className={`${props?.imgClasses} ${props?.className}`}
        />
      )}
    </>
  );
};

Image.propTypes = {
  blok: PropTypes.object,
  src: PropTypes.string,
  label: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string.isRequired,
  srcSet: PropTypes.object,
  image: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
};
