import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { STORY_TYPE } from '../constants/storyblok';
import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { QuickLinks } from './QuickLinks';
import ErrorFallback from './ErrorFallback';

export const QuickLinksContainer = ({ testId }) => {
  const { t, i18n } = useTranslation();

  const { stories, isLoading, error } = useStoryblokProxy({
    starts_with: STORY_TYPE.quicklinks,
    per_page: 1,
    language: i18n.language,
  });

  if (error) {
    return (
      <ErrorFallback
        renderReason={() => <>{t('storyblok.errorTitle')}</>}
        renderMessage={() => (
          <p data-testid="error-text">{t('storyblok.errorMessage')}</p>
        )}
      />
    );
  }

  const quicklinks = !isLoading && stories[0].content.navigation;

  return (
    <div className="w-full lg:bg-lightOrange lg:mt-8 lg:rounded-[32px] lg:p-8">
      {isLoading ? (
        <div
          className="motion-safe:animate-pulse"
          data-testid="quicklinks-loading"
        >
          <div className="animate-pulse flex space-y-10 flex-col w-full">
            <div className="h-4 bg-gray-300 rounded col-span-2 w-1/3 mt-2"></div>
            <div className="h-3 bg-gray-300 rounded col-span-2"></div>
            <div className="h-3 bg-gray-300 rounded col-span-2"></div>
            <div className="h-3 bg-gray-300 rounded col-span-2"></div>
            <div className="h-3 bg-gray-300 rounded col-span-2"></div>
            <div className="h-3 bg-gray-300 rounded col-span-2"></div>
            <div className="h-3 bg-gray-300 rounded col-span-2"></div>
          </div>
        </div>
      ) : (
        <>
          <h3
            className="text-2xl text-brand-secondary font-bold lg:text-[24px] lg:text-orange-500"
            data-testid="quick-links"
          >
            {t('homepage.quickLinks')}
          </h3>
          <div
            className="mt-4 flex flex-wrap gap-3 lg:flex-col lg:mt-8"
            data-testid={testId}
          >
            {quicklinks?.length ? (
              quicklinks.map((quicklink) => (
                <QuickLinks
                  to={quicklink.link}
                  key={quicklink._uid}
                  title={quicklink.title}
                />
              ))
            ) : (
              <p data-testid="no-posts-found">{t('storyblok.noPostsFound')}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

QuickLinksContainer.propTypes = {
  testId: PropTypes.string.isRequired,
};
