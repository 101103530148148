import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { getAppBase } from '../utils/link-utils';

export const useSettings = () => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const history = useHistory();
  const [settings, setSettings] = useState(null);

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  const getSettingsFromAPI = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MICROSERVICE_URL}/api/settings`,
        options
      );

      // Delete tokens and redirect to login form if unauthorised.
      if (response.status === 401) {
        history.push(`${getAppBase()}`);
      }

      const data = await response.json();
      setSettings(data);
      return data;
    } catch (error) {
      setSettings({ language: 'de' });
      return { language: 'de' };
    }
  };

  const postSettingsToAPI = async (updatedSettings) => {
    try {
      await fetch(`${process.env.REACT_APP_MICROSERVICE_URL}/api/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        credentials: 'include',
        body: JSON.stringify(updatedSettings),
      });
    } catch (error) {
      console.error('Error posting settings to API:', error);
    }
  };

  useEffect(() => {
    const getSettings = async () => {
      return await getSettingsFromAPI();
    };
    accessToken && getSettings();
    // We only need to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    settings,
    getSettingsFromAPI,
    postSettingsToAPI,
  };
};

export default useSettings;
