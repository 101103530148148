import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStoryblokProxy } from '../../hooks/useStoryblokProxy';
import {
  NEWS_CATEGORY,
  PAGE_TYPE,
  SORT_BY_PUBLISH_DATE,
  STORIES_PER_PAGE_OVERVIEW,
} from '../../constants/storyblok';
import ErrorFallback from '../../components/ErrorFallback';
import { PageLoading } from '../../components/PageLoading';
import { NewsOverviewPage } from '../NewsOverviewPage';
import { NewsDetailPage } from '../NewsDetailPage';
import { DetailPage } from '../DetailPage';
import { Layout } from '../../components/Layout';
import { Section } from '../../components/Section';
import { DEFAULT_LANGUAGE } from '../../constants/language';

export const StoryblokPage = ({ page }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState(null);

  const isDefaultLanguage = i18n.language === DEFAULT_LANGUAGE;
  const localizedPath = isDefaultLanguage ? '/' : `/${i18n.language}/`;

  const getPathWithoutLocalization = (path) => {
    if (path.startsWith(localizedPath)) {
      return path.substring(localizedPath.length);
    }
    return path;
  };

  const { stories, rels, isLoading, error, totalStories } = useStoryblokProxy({
    starts_with: getPathWithoutLocalization(location.pathname),
    resolve_relations: NEWS_CATEGORY,
    per_page: PAGE_TYPE.newsOverview === page ? STORIES_PER_PAGE_OVERVIEW : 1,
    page: PAGE_TYPE.newsOverview === page ? currentPage : 1,
    language: i18n.language,
    search_term: searchTerm,
    sort_by: PAGE_TYPE.newsOverview === page ? SORT_BY_PUBLISH_DATE : '',
    ...(filter
      ? {
          filter_query: {
            category: {
              // Relation based on category
              in: filter,
            },
          },
        }
      : {}),
  });

  if (isLoading) {
    return (
      <Layout>
        <Section>
          <PageLoading />
        </Section>
      </Layout>
    );
  }

  if (error || (!stories && searchTerm === '')) {
    return (
      <ErrorFallback
        renderReason={() => <>{t('storyblok.errorTitle')}</>}
        renderMessage={() => <p>{t('storyblok.errorMessage')}</p>}
      />
    );
  }

  return (
    <>
      {PAGE_TYPE.newsOverview === page && (
        <NewsOverviewPage
          location={PAGE_TYPE.newsOverview}
          stories={stories}
          filter={filter}
          setFilter={setFilter}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalStories={totalStories}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
      {PAGE_TYPE.newsDetail === page && (
        <NewsDetailPage story={stories[0]} category={rels[0]} />
      )}
      {PAGE_TYPE.static === page && <DetailPage story={stories[0]} />}
    </>
  );
};

StoryblokPage.propTypes = {
  page: PropTypes.string.isRequired,
};
