export const getLink = (link) => {
  if (link?.linktype !== 'story') {
    return link;
  }

  return link.cached_url;
};

export const getAppBase = () => {
  const isEnglish = window.location.pathname.startsWith('/en');
  return isEnglish ? '/en' : '';
};

export const createNavLink = (link) => {
  const HOMEPAGE_SLUG = 'home';

  if (HOMEPAGE_SLUG === link) {
    return getAppBase();
  }

  return `${getAppBase()}/${link}`;
};
