import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ClockIcon } from './icons/Icons';
import { formatDate } from '../utils/date-utils';
import { Image } from './Image';
import { PAGE_TYPE } from '../constants/storyblok';

export const NewsCard = ({ story, location }) => {
  return (
    <div
      className={`mb-4 ${
        PAGE_TYPE.newsDetail === location ? 'lg:mb-4' : 'lg:mb-8'
      }`}
      data-testid="news-card"
    >
      <Link
        data-testid="news-link"
        className={`flex items-center lg:items-start lg:flex-col ${
          PAGE_TYPE.newsDetail === location ? '!items-center !flex-row' : ''
        }`}
        to={`/${story.full_slug}`}
      >
        <Image
          className={`flex-shrink-0 mr-4 rounded-xl overflow-hidden lg:w-full ${
            PAGE_TYPE.newsDetail === location ? '!w-28 h-28' : ''
          }`}
          imgClasses="w-28 h-28 lg:w-full lg:h-full lg:max-h-40 xl:max-h-52"
          image={story.content.featured}
        />
        <div
          className={`flex-grow flex flex-col lg:mt-4 ${
            PAGE_TYPE.newsDetail === location ? 'lg:mt-0' : ''
          }`}
        >
          <h2
            data-testid="news-title"
            className="font-medium text-slate-950 lg:text-xl !leading-6 font-founders-grotesque"
          >
            {story.content.title ?? story.name}
          </h2>
          <span className="flex items-center mt-2">
            <ClockIcon />
            <span
              data-testid="news-date"
              className="text-slate-950 text-xs lg:text-sm ml-2"
            >
              {formatDate(story.first_published_at)}
            </span>
          </span>
        </div>
      </Link>
    </div>
  );
};

NewsCard.propTypes = {
  story: PropTypes.shape({
    name: PropTypes.string.isRequired,
    full_slug: PropTypes.string.isRequired,
    first_published_at: PropTypes.string.isRequired,
    content: PropTypes.shape({
      featured: PropTypes.object.isRequired,
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.string,
};
