import { useState } from 'react';
import { useStoryblokProxy } from '../hooks/useStoryblokProxy';
import { useTranslation } from 'react-i18next';
import { NEWS_CATEGORY, STORY_TYPE } from '../constants/storyblok';

export const Filters = ({ filter, setFilter }) => {
  const { i18n, t } = useTranslation();

  const {
    rels: filters,
    isLoading: isLoadingFilters,
    error: errorFilters,
  } = useStoryblokProxy({
    starts_with: STORY_TYPE.news,
    resolve_relations: NEWS_CATEGORY,
    language: i18n.language,
  });

  const [selectedFilter, setSelectedFilter] = useState(filter ?? null);

  const setFilterHandler = (value) => {
    setFilter(value);
    setSelectedFilter(value);
  };

  return (
    <div data-testid="filters-container">
      {errorFilters || filters?.length === 0 ? (
        <p data-testid="filters-error">{t('filters.error')}</p>
      ) : isLoadingFilters ? (
        <div
          className="motion-safe:animate-pulse pt-2 pb-4 lg:pb-8"
          data-testid="quicklinks-loading"
        >
          <div className="animate-pulse flex space-x-5">
            <div className="h-6 bg-gray-300 rounded w-20"></div>
            <div className="h-6 bg-gray-300 rounded w-20"></div>
            <div className="h-6 bg-gray-300 rounded w-20"></div>
            <div className="h-6 bg-gray-300 rounded w-20"></div>
            <div className="h-6 bg-gray-300 rounded w-20"></div>
          </div>
        </div>
      ) : (
        <ul
          data-testid="filter-buttons"
          className="flex gap-5 overflow-y-auto pt-2 pb-4 lg:pb-8"
        >
          <button
            className={`${
              selectedFilter === null ? 'decoration-4' : 'decoration-light'
            } cursor-pointer text-lg shrink-0 underline underline-offset-[15px]`}
            onClick={() => setFilterHandler(null)}
          >
            {t('filters.all')}
          </button>
          {filters?.map((filter) => (
            <button
              key={filter.uuid}
              onClick={() => setFilterHandler(filter.uuid)}
              className={`${
                filter.uuid === selectedFilter
                  ? 'decoration-4'
                  : 'decoration-light'
              } cursor-pointer text-lg shrink-0 underline underline-offset-[15px]`}
              data-testid="filter-option"
            >
              {filter.name}
            </button>
          ))}
        </ul>
      )}
    </div>
  );
};
