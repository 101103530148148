import i18n from 'i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en';
import de from './i18n/de';
import DetectKnowLocaleFromURL from './i18n/DetectKnownLocaleFromURL';
import { DEFAULT_LANGUAGE } from './constants/language';

const browserLanguageDetector = new BrowserLanguageDetector();
browserLanguageDetector.addDetector(DetectKnowLocaleFromURL);

const resources = {
  de,
  en,
};

export const locales = Object.keys(resources);

i18n.on('languageChanged', function (lng) {
  // if the language we switched to is the default language we need to remove the /en from URL
  if (lng === DEFAULT_LANGUAGE) {
    if (window.location.pathname.includes(`/${DEFAULT_LANGUAGE}`)) {
      const newUrl = window.location.pathname.replace(
        `/${DEFAULT_LANGUAGE}`,
        ''
      );
      window.location.replace(newUrl);
    }
  }
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(browserLanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    whitelist: ['de', 'en'],
    lng: ['de'],
    detection: {
      order: [
        DetectKnowLocaleFromURL.name,
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'subdomain',
      ],
      checkWhitelist: true,
      knownLocales: locales,
    },
    caches: [],
  });

export default i18n;
