import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useOktaAuth } from '@okta/okta-react';
import { getAppBase } from '../utils/link-utils';

export const ERROR_MESSAGE = 'Failed to fetch stories response';

export const useStoryblokProxy = (args, abortRequest) => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();

  const params = qs.stringify({
    ...args,
    resolve_relations: args?.resolve_relations,
    content_type: args?.content_type,
    version: process.env.REACT_APP_STORYBLOK_CONTENT_STAGE ?? 'draft',
  });

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  const { isLoading, error, data } = useQuery({
    queryKey: [args.start_with + params],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_MICROSERVICE_URL}/content/cdn/stories?${params}`,
        options
      );

      // Delete tokens and redirect to login form if unauthorised.
      if (response.status === 401) {
        history.push(`${getAppBase()}`);
      }

      if (!response.ok) {
        throw new Error(ERROR_MESSAGE);
      }

      return {
        ...(await response.json()),
        totalStories: parseInt(response.headers.get('Total')),
      };
    },
    select: (data) => ({
      stories: data.stories,
      rels: data.rels,
      rel_uuids: data.rel_uuids,
      totalStories: data.totalStories,
    }),
    enabled: !abortRequest,
  });

  return {
    stories: data?.stories.length ? data.stories : null,
    rels: data?.rels,
    rels_uuids: data?.rel_uuids,
    isLoading,
    error,
    totalStories: data?.totalStories,
  };
};
