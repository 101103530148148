import PropTypes from 'prop-types';
import { CommentIcon, LikeIcon } from '../components/icons/Icons';
import { PILL_ICON_TYPE } from '../constants/pillIcon';

export const Pill = ({
  onClick,
  text,
  icon,
  as: Component = 'button',
  children,
  isLikedByUser = false,
  ...rest
}) => {
  const likeIconClass = isLikedByUser ? 'fill-brand-secondary mr-2' : 'mr-2';

  return (
    <Component
      data-testid="pill"
      onClick={onClick}
      className={`py-2 px-3 rounded-full text-brand-secondary ${
        isLikedByUser ? `bg-brand-primary` : `bg-lightGray`
      }  inline-flex font-medium leading-none items-center self-start hover:bg-gray-400`}
      {...rest}
    >
      {PILL_ICON_TYPE.like === icon && (
        <>
          <LikeIcon className={likeIconClass} />
          <span className="text-sm lg:text-base">{text}</span>
        </>
      )}
      {PILL_ICON_TYPE.comment === icon && (
        <>
          <CommentIcon className="mr-2" />
          <span className="text-sm lg:text-base ml-1">{children}</span>
        </>
      )}
    </Component>
  );
};

Pill.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.object,
  isLikedByUser: PropTypes.bool,
};
