export const DEFAULT_LANGUAGE = 'de';

export const LANGUAGES = [
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'en',
    name: 'English',
  },
];
