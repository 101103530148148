import PropTypes from 'prop-types';

export const Section = ({ className, children }) => {
  return (
    <div
      data-testid="section"
      className={`pt-5 px-5 xl:px-0 lg:pt-8 ${className ?? ''}`}
    >
      {children}
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
