import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ArrowIcon } from './icons/Icons';
import { getLink } from '../utils/link-utils';

export const QuickLinks = ({ title, to }) => {
  return (
    <Link
      data-testid="quick-link"
      className="py-2 px-3 rounded-xl text-brand-tertiary inline-flex font-medium items-center self-start bg-lightGray hover:bg-gray-400 lg:bg-transparent lg:text-darkGray lg:p-0 lg:hover:bg-transparent lg:hover:text-brand-tertiary"
      title={`Link to ${title}`}
      to={getLink(to)}
    >
      <span className="hidden lg:flex stroke-brand-tertiary mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="9"
          viewBox="0 0 6 9"
        >
          <path
            d="M1 0.75L4.57333 4.32333C4.62023 4.37017 4.64658 4.43372 4.64658 4.5C4.64658 4.56628 4.62023 4.62983 4.57333 4.67667L1 8.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </span>
      <span className="text-sm mr-2 lg:text-base lg:font-bold lg:m-0">
        {title}
      </span>
      <ArrowIcon className="lg:hidden" />
    </Link>
  );
};

QuickLinks.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.object.isRequired,
};
