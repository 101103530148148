import { Component, useEffect, useRef, useState } from 'react';
import {
  Link,
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';

import { authClient } from './auth/authClient';
import HomePage from './pages/HomePage';
import ErrorFallback from './components/ErrorFallback';
import { StoryblokPage } from './pages/storyblok/StoryblokPage';
import { SettingsPage } from './pages/SettingsPage';
import { PAGE_TYPE } from './constants/storyblok';
import { DEFAULT_LANGUAGE } from './constants/language';
import { SettingsContextProvider } from './context/settingsContext';

const history = createBrowserHistory();
const queryClient = new QueryClient();

if ('production' === process.env.REACT_APP_MODE) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

export const App = ({ history }) => {
  const prevLocation = useRef();
  const [appBase, setAppBase] = useState('');

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    authClient.tokenManager.on('expired', (key) => {
      // Redirect to home when token expires.
      history.push('/');
    });
  });

  useEffect(() => {
    const isEnglish = window.location.pathname.startsWith('/en');
    const languageToSet = isEnglish ? 'en' : DEFAULT_LANGUAGE;

    i18n.changeLanguage(languageToSet);
    setAppBase(isEnglish ? '/en' : '');
  }, [i18n, appBase]);

  useEffect(() => {
    history.listen((location, action) => {
      /**
       * Only send pageview events on PUSH actions
       * and only if the pathname has changed
       * this is important otherwise we will get
       * double pageview events
       **/
      //
      if (action === 'PUSH' && location.pathname !== prevLocation.current) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            page: location.pathname,
          },
        });
        prevLocation.current = location.pathname;
      }
    });
  }, [history]);

  return (
    <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
      <SettingsContextProvider>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path="/login/callback" exact={true}>
              <LoginCallback
                errorComponent={() => (
                  <ErrorFallback
                    renderReason={() => (
                      <>You don't have access to the application.</>
                    )}
                    renderMessage={() => <p>Please contact ...</p>}
                    authError={true}
                  />
                )}
              />
            </Route>
            <SecureRoute path={`${appBase}/cookie-policy`} exact={true}>
              <StoryblokPage page={PAGE_TYPE.static} />
            </SecureRoute>
            <SecureRoute path={`${appBase}/news/:slug`} exact={true}>
              <StoryblokPage page={PAGE_TYPE.newsDetail} />
            </SecureRoute>
            <SecureRoute path={`${appBase}/settings`} exact={true}>
              <SettingsPage />
            </SecureRoute>
            <SecureRoute path={`${appBase}/:pages/:slug`} exact={true}>
              <StoryblokPage page={PAGE_TYPE.static} />
            </SecureRoute>
            <SecureRoute path={`${appBase}/news`} exact={true}>
              <StoryblokPage page={PAGE_TYPE.newsOverview} />
            </SecureRoute>
            <SecureRoute path={`${appBase}/:slug`} exact={true}>
              <StoryblokPage page={PAGE_TYPE.static} />
            </SecureRoute>
            <SecureRoute path={appBase} exact={true}>
              <HomePage />
            </SecureRoute>
            <Route path="*">
              <ErrorFallback
                renderReason={() => <>{i18n.t('404.notFoundTitle')}</>}
                renderMessage={() => (
                  <Trans
                    i18nKey="404.notFoundText"
                    components={{
                      homeLink: (
                        <Link
                          className="underline text-bold"
                          to="/"
                          title="Homepage"
                        />
                      ),
                    }}
                  />
                )}
              />
            </Route>
          </Switch>
        </QueryClientProvider>
      </SettingsContextProvider>
    </Security>
  );
};

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <Router>
        <AppWithRouterAccess />
      </Router>
    );
  }
}

const ApplicationErrorFallback = () => (
  <ErrorFallback
    renderReason={() => <>Something went wrong</>}
    renderMessage={() => (
      <>An error occurred in the application. We have been notified.</>
    )}
  />
);

const RouterAppWithSentry = Sentry.withErrorBoundary(RouterApp, {
  fallback: ApplicationErrorFallback,
});

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    listen: PropTypes.func.isRequired,
  }).isRequired,
};

export default RouterAppWithSentry;
