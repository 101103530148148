import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ErrorFallback(props) {
  const { t } = useTranslation();

  return (
    <div className="px-4 relative" data-testid="error-fallback-container">
      <div className="flex flex-col h-[calc(100vh-6rem)] justify-center">
        <div className="md:w-1/2">
          <h1 className="uppercase text-6xl md:text-7xl lg:text-8xl font-extrabold font-founders-grotesque">
            {t('404.oops')}
          </h1>
          <span className="md:text-lg uppercase pb-4 relative flex">
            {props.renderReason()}
          </span>

          {props.renderMessage()}
        </div>
      </div>
    </div>
  );
}

ErrorFallback.propTypes = {
  renderReason: PropTypes.func.isRequired,
  renderMessage: PropTypes.func.isRequired,
};
