import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../components/Section';
import { Layout } from '../components/Layout';
import { PageHeader } from '../components/PageHeader';
import { CheckIcon } from '../components/icons/Icons';
import { Toggle } from '../components/Toggle';
import { LANGUAGES } from '../constants/language';
import useSettingsContext from '../hooks/useSettingsContext';
import { useNotifications } from '../hooks/useNotifications';
import { NEWS_SEGMENT } from '../constants/notifications';

export const SettingsPage = () => {
  const { t, i18n } = useTranslation();
  const [toggleNotification, setToggleNotification] = useState(false);
  const { getSubscription } = useNotifications();

  const { handleLanguageChange, handleNotificationChange } =
    useSettingsContext();

  useEffect(() => {
    setToggleNotification(getSubscription());
    // We only need to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificationToggle = () => {
    handleNotificationChange(NEWS_SEGMENT, !toggleNotification);
    setToggleNotification((prevChecked) => !prevChecked);
  };

  return (
    <Layout>
      <PageHeader
        title={t('settings.title')}
        subtitle={t('settings.subTitle')}
      />
      <Section>
        <div className="flex mt-3.5 flex-col">
          <h2 className="text-2xl font-medium text-brand-secondary font-founders-grotesque">
            {t('settings.language')}
          </h2>
          <div className="flex gap-3">
            {LANGUAGES.map(({ code }) => {
              return (
                <button
                  data-testid={`toggle-${code}`}
                  onClick={() => handleLanguageChange(code)}
                  key={code}
                  className={`${
                    code === i18n.resolvedLanguage
                      ? 'text-white bg-brand-tertiary hover:bg-brand-tertiary'
                      : 'text-brand-secondary hover:text-white bg-light hover:bg-brand-tertiary'
                  } my-3 py-3 px-5 rounded-full inline-flex font-medium items-center`}
                >
                  <span className="text-sm lg:text-base">
                    {t(`settings.${code}`)}
                  </span>
                  {code === i18n.resolvedLanguage && (
                    <CheckIcon className="ml-2" />
                  )}
                </button>
              );
            })}
          </div>
        </div>

        <div className="flex mt-3.5">
          <h2 className="text-2xl font-medium text-brand-secondary font-founders-grotesque">
            {t('settings.notifications')}
          </h2>
        </div>

        <div className="flex justify-between items-center w-full lg:max-w-screen-sm mt-3.5">
          <Toggle
            title={t('settings.enableNotifications')}
            value={toggleNotification}
            handleToggle={handleNotificationToggle}
          />
        </div>
      </Section>
    </Layout>
  );
};
